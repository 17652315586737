* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;
  outline: none;
}

canvas {
  display: block;
}

canvas {
  height: auto !important;
  width: 100% !important;
}

body {
  background-color: #00010D;
  color: #EFE0D0;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
}

button {
  border: none;
  outline: none;
}

input[type=checkbox] {
  display: none;
}
input[type=checkbox] + label {
  background: url("assets/checkbox-inactive.png") no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 128px;
  padding: 0;
  width: 128px;
}
input[type=checkbox]:checked + label {
  background: url("assets/checkbox-active.png") no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 128px;
  padding: 0;
  width: 128px;
}

#root {
  /* taken from https://stackoverflow.com/questions/20590239/maintain-aspect-ratio-of-div-but-fill-screen-width-and-height-in-css/36295495#20593342 */
  width: 100vw;
  height: 56.22vw; /* 750/1334=5622  */
  max-height: 100vh;
  max-width: 177.87vh; /* 1334/750=17787 */
  margin: auto;
  position: absolute;
  top: 0; bottom: 0; /* vertical center */
  left: 0; right: 0; /* horizontal center */
}
